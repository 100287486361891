import React, { useContext } from "react"
import { StateContext } from "../../providers/StateProvider"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import styles from "../Result/Result.module.scss";
import content from "../../content/content.json"
import Button from "../Button"
import Stage from "../Stage"
import Text from "../Text"
import StageImage1 from "../../images/stages/Result_Stage_1.jpg"
import StageImage1Mobile from "../../images/stages/Result_Stage_1_m.jpg"
import StageImage2 from "../../images/stages/Result_Stage_2.jpg"
import StageImage2Mobile from "../../images/stages/Result_Stage_2_m.jpg"
import StageImage3 from "../../images/stages/Result_Stage_3.jpg"
import StageImage3Mobile from "../../images/stages/Result_Stage_3_m.jpg"
import StageHeadline1 from "../../images/ergebnis/ergebnis1.svg"
import StageHeadline2 from "../../images/ergebnis/ergebnis2.svg"
import StageHeadline3 from "../../images/ergebnis/ergebnis3.svg"

const Result = () => {
    
	const { state, actions } = useContext(StateContext)

    let aList = state.aList || false
    //console.log(aList)
    if (aList.length === 0) {
        navigate("/")
    }

    let yes = 0
    let no = 0

    Array.from(aList).forEach(el => {
        if (el === "true") {
            yes++
        }
        else if (el === "false"){
            no++
        }
    });
    //console.log("yes: " + yes)
    //console.log(" no: " + no)

    const results = content.pages.result
    const context = (yes >= results.result1.valid) ? "result1" : (yes === results.result3.valid) ? "result3" : "result2"
    const StageImage = (context === "result1") ? StageImage1 : (context === "result2") ? StageImage2 : StageImage3
    const StageImageMobile = (context === "result1") ? StageImage1Mobile : (context === "result2") ? StageImage2Mobile : StageImage3Mobile
    const StageHeadline = (context === "result1") ? StageHeadline1 : (context === "result2") ? StageHeadline2 : StageHeadline3
    const description = content.app_description // change here if the meta description of the result page should be depending on the result

    const IconRetry = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451 451" className={styles.Result__Redo__Link__Icon}>
                <g>
                    <g transform="translate(-300.000000, -350.000000)" className={styles.Result__Redo__Link__Icon__Fill}>
                        <path d="M525.5,350 C590.059508,350 648.282466,377.129991 689.389912,420.611011 L730,380 L730,490 L620,490 L661.092,448.908 L661.07963,448.895638 C627.223499,412.654767 579.007402,390 525.5,390 C423.051179,390 340,473.051179 340,575.5 C340,677.948821 423.051179,761 525.5,761 C627.948821,761 711,677.948821 711,575.5 C711,566.84842 710.407726,558.335173 709.261512,549.998594 L749.574161,550.000069 C750.516163,558.369926 751,566.87846 751,575.5 C751,700.040211 650.040211,801 525.5,801 C400.959789,801 300,700.040211 300,575.5 C300,450.959789 400.959789,350 525.5,350 Z" id="icon_try_again"></path>
                    </g>
                </g>
            </svg>
        )
    }

    const ActionRetry = () => {
        actions.setAnswerList([])
        actions.setList([])
        navigate("/frage1")
    }

    return (
        <section className={styles.Result}>

			<Helmet>
				<meta name="description" content={description} />
			</Helmet>

            <Stage
                StageImage={StageImage}
                StageImageMobile={StageImageMobile}
                StageImageAlt={""}
                StageHeadlinePosition={context}
                StageHeadline={StageHeadline}
                StageHeadlineAlt={""}
            />
            {/*<div className={styles.Result__Debug}>
                Debug: on<br/>
                Fortschritt: 10<br/>
                True: {yes}<br/>
                Context: {context}
            </div>*/}

            <article className={styles.Result__Copy}>
                <Text>{results[context].copy}</Text>
                <Button primary={true} external={true} title={results.cta_label} target={results.cta_target} />
            </article>

            {context !== "result3" &&
            <article className={styles.Result__Application}>
                <Text>{results.application.copy}</Text>
                <Button secondary={true} external={true} title={results.application.cta_label} target={results.application.cta_target} />
            </article>
            }

            <article className={styles.Result__Redo}>
                <Text>
                    <a href={() => false} className={styles.Result__Redo__Link} onClick={()=>ActionRetry()}>
                        <IconRetry /> <span>Test neu starten</span>
                    </a>
                </Text>
            </article>

        </section>
    );
}

export default Result;