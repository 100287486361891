import React from "react"
import Layout from "../layouts/Base"
import Result from "../components/Result"
import "../style/main.scss"

export default function Questions() {
	
	return (
		<Layout>
			<Result />
		</Layout>
	)
}
